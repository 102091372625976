@use '../abstracts/variables' as var;

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;
  color: var.$content;

  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  p {
    line-height: 150%;
    font-size: 16px;
  }

  &.blurred {
    &:before {
      content: '';
      background: rgba(0, 0, 0, .7);
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 150%;
      height: 150%;
      z-index: 4;
      backdrop-filter: blur(4px);
    }
  }

}

::selection {
  color: var.$dark;
  background: var.$secondary;
}

footer {
  background: #181819 url("@images/footer-tire.png") no-repeat bottom right;
  padding: 24px 0 32px;
  margin-top: 0;
  border-radius: 16px;
  margin-bottom: 32px !important;

  @media (max-width: var.$ipad) {
    padding: 16px 0;
    margin-top: 24px;
    border-radius: 16px;
  }

}

.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 32px 16px;

  @media (min-width: var.$uhd){
    padding: 32px 80px;
  }

  @media (min-width: var.$ipad) {
    padding: 48px 64px 0;
  }

  @media (min-width: var.$desktop) {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 48px 80px 0;
  }

  &__contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 72px;
    padding-bottom: 40px;

    @media (max-width: var.$ipad) {
      padding-right: 0;
    }

    @media (max-width: 360px) {
      padding-right: 0;
    }

    @media (min-width: var.$ipad) {
      width: 100%;
    }

    @media (min-width: var.$desktop) {
      width: 50%;
      padding-bottom: 0;
    }

    &-details {
      padding: 0;
      margin: 0;
      list-style: none;
      padding-bottom: 24px;
    }
  }

  &__details {
    &-phone {
      font-size: 1.725rem;
      font-weight: 700;
      margin-bottom: 24px;

      a {
        color: var.$white;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: all 225ms ease-in-out;

        &:hover {
          color: var.$secondary;
        }

        svg {
          width: 32px;
          margin-left: 16px;
          animation: ringing 2s infinite;

          path {
            fill: #7C7C81;
          }
        }
      }
    }

    &-link {
      font-size: 1rem;
      font-weight: 500;

      color: var.$dark-light;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &.mail {
        padding: 24px 0;
        color: var.$dark-light;
      }

      &.address {
        position: relative;
        padding-left: 37px;
        line-height: 160%;
        color: var.$dark-light;
        margin-bottom: 8px;

        strong {
          color: #BFBFBF;
          font-weight: 600;
        }

        &:before {
          content: url(@images/icons/map.svg);
          position: absolute;
          top: 1px;
          left: -2px;
        }
      }

      svg {
        width: 32px;
      }

      a {
        display: flex;
        align-items: center;
        color: #b5b5ba;
        transition: all 225ms ease-in-out;
        font-weight: 600;

        &:hover {
          color: var.$secondary;
        }

        svg {
          width: 20px;
          margin-right: 16px;
        }
      }

      &.hours {
        display: flex;
        flex-direction: row;
        line-height: 200%;

        @media (max-width: 680px) {
          flex-direction: column;
        }

        span {
          width: 180px;
          white-space: nowrap;
        }

        strong {
          margin-left: 24px;
          white-space: nowrap;
          @media (max-width: 680px) {
            margin-left: 0;
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    padding-top: 24px;

    @media (min-width: var.$ipad) {
      width: 33.3%;
      padding-top: 0;
    }

    &-title {
      padding-bottom: 24px;
      margin-bottom: 24px;
      list-style-type: none;
      color: var.$white;
      font-size: 1.225rem;
      font-weight: 700;
      position: relative;

      &.hourTitle {
        font-size: 1rem;
        font-weight: 600;
        color: #BFBFBF;
      }

      &.linkTitle {
        font-size: 1rem;
        color: #BFBFBF;

        &:after {
          background: #7C7C81;
        }
      }

      &.related {
        &:after {
          background: var.$secondary;
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 2px;
        background: var.$white;
        left: 0;
        bottom: 0;
      }
    }

    &-item {
      list-style-type: none;
      margin-bottom: 16px;

      a {
        color: #b5b5ba;
        transition: all 225ms ease-in-out;

        &:hover {
          color: var.$secondary;
        }
      }
    }
  }

  &__hr {
    display: flex;
    width: 100%;
    height: 1px;
    border: none;
    background: #353537;
    margin: 24px 0;
  }

  &__brands {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    justify-content: space-between;
    //padding: 0 64px;
    margin: 0;
    width: 100%;
    padding: 0 16px;

    @media (max-width: 400px) {
      flex-direction: column;
      gap: 16px;
    }

    @media (min-width: var.$ipad) {
      padding: 48px 64px 0;
    }

    @media (min-width: var.$desktop) {
      justify-content: flex-start;
    }

    &-link {
      margin-right: 0;

      @media (min-width: var.$desktop) {
        margin-right: 64px;
      }

      img {
        opacity: 1;
        transition: all 225ms ease-in-out;
        height: 32px;
      }

      &:hover {
        img {
          opacity: .6;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

  }

  &__copyrights {
    color: var.$dark-light;
    font-size: .825rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 16px;
    margin-bottom: 24px;

    @media (min-width: var.$ipad) {
      padding: 48px 64px 0;
    }

    @media (min-width: var.$ipad) {
      flex-direction: row;
    }

    p {
      a {
        color: var.$dark-light;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }

      &:nth-child(2) {
        a {
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

  }

}

@-webkit-keyframes ringing {
  0% {
  }
  10% {
    transform: rotate(5deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  90% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}