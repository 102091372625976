@use '@styles/abstracts/_variables.scss' as var;
@use '@styles/main.scss';

@import "tiny-slider/dist/tiny-slider";
@import "tippy.js/dist/tippy.css";

@import "nice-select2/dist/css/nice-select2";
//@import "nice-select2/dist/css/style";


.tires {
  padding: 0;
  background: transparent;

  &__menu {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0;
    margin: 0;
    list-style-type: none;

    &-item {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 0 20px;
      background: var.$dark;
      color: var.$white;
      font-weight: 700;
      height: 56px;
      position: relative;
      cursor: pointer;
      transition: all 225ms ease-in-out;

      @media (max-width: var.$ipad) {
        width: 100%;
        justify-content: center;
        text-align: center;
      }

      span {
        @media (max-width: 1024px) {
          font-size: .875rem;
        }

        &.mobile {
          display: flex;
          @media (min-width: var.$ipad) {
            display: none;
          }
        }

        &.desktop {
          display: none;
          @media (min-width: var.$ipad) {
            display: flex;
          }
        }
      }

      a {
        display: flex;
        gap: 12px;
        color: var.$white;
        width: 100%;
        height: 100%;
        align-items: center;
        @media (max-width: var.$ipad) {
          width: 100%;
          justify-content: center;
        }
      }

      svg {
        @media (max-width: 540px) {
          display: none;
        }

        path {
          fill: var.$white;
          transition: all 225ms ease-in-out;
        }
      }

      &:hover {
        filter: brightness(80%);
      }

      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 48px;
        background: var.$dark-light;
        right: 0;
      }

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;

        &:after {
          display: none;
        }
      }

      &.active {
        background: var.$white;
        color: var.$dark;
        height: 64px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        cursor: default;

        &:after {
          display: none;
        }

        &:hover {
          filter: brightness(100%);
        }

        svg {
          path {
            fill: var.$dark;
          }
        }

      }
    }
  }

  &__content {
    padding: 32px 32px;
    background: var.$white;
    border-top-right-radius: 16px;
    width: 100%;

    @media (max-width: var.$ipad) {
      border-top-right-radius: 0;
    }

    .tiresForm {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 32px;

      @media (max-width: var.$ipad) {
        flex-direction: column;
      }

      @media (min-width: var.$ipad) and (max-width: var.$desktop) {
        flex-direction: column;
      }

      &.hidden {
        display: none;
      }

      &__title {
        display: flex;
        flex-direction: column;
        width: fit-content;
        color: var.$dark;

        @media (max-width: var.$ipad) {
          flex-direction: row;
          gap: 16px;
        }

        @media (min-width: var.$ipad) and (max-width: var.$desktop) {
          width: 100%;
        }

        @media (min-width: var.$ipad) and (max-width: var.$desktop) {
          flex-direction: row;
          gap: 16px;
          align-items: center;
        }

        svg {
          margin: 0;
          padding: 0;
          margin-bottom: 8px;
          @media (min-width: var.$ipad) and (max-width: var.$desktop) {
            margin-bottom: 0;
          }

          path {
            fill: var.$dark;
          }
        }

        &-text {
          font-weight: 400;
          font-size: 1.15rem;
          line-height: 150%;
          width: max-content;
          padding: 0;
          margin: 0;
          @media (max-width: var.$ipad) {
            //width: 100%;
            white-space: nowrap;
          }

          br {
            @media (min-width: 490px) and (max-width: var.$ipad) {
              display: none;
            }
            @media (min-width: var.$ipad) and (max-width: var.$desktop) {
              display: none;
            }
          }
        }
      }

      &__form {
        display: flex;
        flex-direction: row;
        gap: 24px;
        width: 100%;

        @media (max-width: 430px) {
          gap: 8px;
          flex-wrap: wrap;
        }

        @media (min-width: 380px) and (max-width: var.$ipad) {
          flex-direction: row;
          gap: 16px;
          flex-wrap: wrap;
        }

        @media (min-width: 430px) {
          gap: 0;
        }

        @media (min-width: var.$ipad) and (max-width: var.$desktop) {
          gap: 16px !important;
        }

        @media (min-width: var.$desktop) {
          gap: 16px;
        }

        &-single {
          display: flex;
          flex-direction: column;
          width: 100%;

          @media (min-width: 380px) and (max-width: 430px) {
            width: calc(50% - 8px);
          }

          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            @media (min-width: 430px) and (max-width: var.$ipad) {
              width: 33.3%;
            }
          }

          &:nth-child(4) {
            .nice-select {
              border-radius: 8px;
            }
          }

          label {
            font-size: .875rem;
            padding-bottom: 8px;
            font-weight: 600;
            color: var.$dark-light;

            &:after {
              content: ':';
            }
          }

          .form {
            &__content {
              display: flex;
              flex-direction: row;

              &-select {
                align-items: center;
                height: 56px;
                width: 100%;
                display: none;
                border-radius: 0;
                border-color: #F2F2F2;

                &.nice-select {
                  display: flex;
                  border-top-right-radius: 8px;
                  border-bottom-right-radius: 8px;
                  font-weight: 700;
                  font-size: .875rem;
                  @media (max-width: 430px) {
                    border-radius: 8px;
                  }
                  @media (min-width: 430px) and (max-width: var.$ipad) {
                    border-radius: 0;
                  }
                }
              }

              &-modal {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                height: 56px;
                min-width: 56px;
                width: 56px;
                padding: 0;
                margin: 0;
                background: #F2F2F2;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;

                @media (max-width: var.$ipad) {
                  display: none;
                }

                svg {
                  width: 24px;
                  height: 24px;
                  color: #A0A0A0;
                }
              }
            }
          }
        }
      }

      &__button {
        display: flex;
        width: fit-content;
        @media (min-width: 0) and (max-width: var.$desktop) {
          width: 100%;
        }

        button {
          width: max-content;
          white-space: nowrap;
          height: 80px;
          gap: 16px;

          @media (min-width: 0) and (max-width: var.$desktop) {
            height: 56px;
            width: 100%;
          }

          svg {
            width: 24px;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: var.$primary;
    padding: 16px 32px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;


    @media (max-width: 1024px) {
      flex-direction: column;
    }

    &-value {
      display: flex;
      flex-direction: row;
      gap: 32px;
      padding: 0;
      margin: 0;

      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 0;
        border: 2px solid var.$white;
        padding: 0 16px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var.$white;
        gap: 4px;
        position: relative;
        white-space: wrap;
        font-size: .875rem;

        @media (max-width: 1024px) {
          margin: 0;
          border-bottom: 1px solid lighten(#009EE2, 10%);
          padding: 16px 0;
        }


        svg {
          width: 24px;
          margin-right: 8px;

          @media (max-width: 1024px) {
            order: 2;
            margin-left: auto;
          }
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        &:after {
          content: '';
          width: 4px;
          height: 4px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -16px;
          background: white;
          border-radius: 16px;
          @media (max-width: 1024px) {
            display: none;
          }
        }

      }
    }

    &-rating {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      @media (max-width: 1024px) {
        background: var.$white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-top: 0;
        padding: 0 0 0 16px;
      }

      .rating {
        display: flex;
        flex-direction: column;
        font-size: .725rem;
        font-weight: 500;
        color: var.$white;
        align-items: flex-end;

        @media (max-width: 1024px) {
          align-items: flex-start;
          color: var.$dark;
        }

        @media (min-width: 1024px) and (max-width: 1140px) {
          display: none;
        }

        strong {
          padding-top: 2px;
          font-size: .875rem;
          @media (max-width: 430px) {
            width: 2.5ch;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }

      .stars {
        display: flex;
        flex-direction: row;
        background: var.$white;
        padding: 16px;
        border-radius: 4px;
        color: #EF801A;

        @media (max-width: 1024px) {
          margin-left: auto;
        }

        svg {
          width: 20px;
        }
      }

    }
  }

}

.partner {
  display: flex;
  position: relative;
  aspect-ratio: 6 / 1;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 16px;

  @media (min-width: var.$desktop) {
    border-radius: 16px;
  }

  &__double {
    aspect-ratio: 6 / 1.5;
  }

  a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

}

.vprop {
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    padding: 0 40px 32px;

    @media (max-width: var.$ipad) {
      padding: 0 24px 24px;
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 32px;
      border: 1px solid #F2F2F2;
      border-radius: 16px;
      width: 100%;
      @media (min-width: var.$ipad) {
        width: calc(50% - 16px);
      }
      @media (min-width: var.$desktop) {
        width: calc(25% - 32px);
        flex-direction: column;
        justify-content: center;
      }

      img {
        margin-right: 32px;
        padding-right: 32px;
        border-right: 1px solid darken(#fff, 10%);
        @media (min-width: var.$desktop) {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
          margin-bottom: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid darken(#fff, 10%);
        }
      }

      span {
        font-size: 1rem;
        font-weight: 700;
        line-height: 150%;
        color: var.$content;
        @media (min-width: var.$desktop) {
          text-align: center;
        }
      }
    }
  }

  .link {
    display: flex;
    justify-content: center;
    padding: 0 24px 40px;

    @media (min-width: var.$desktop) {
      padding: 0 40px 40px;
    }

  }
}

.quote {
  padding: 0 40px 40px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: auto;
  @media (min-width: var.$desktop) {
    gap: 32px;
  }

  @media (max-width: var.$ipad) {
    padding: 0 24px 24px;
  }

  &__single {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 16px;
    width: 100%;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.15);
    min-width: 280px;

    &-header {
      display: flex;
      justify-content: space-between;
      font-size: .875rem;
    }

    &-content {
      border-top: 1px solid darken(#fff, 10%);
      padding: 8px 0;
      margin: 16px 0;
      height: 100%;
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      @media (min-width: var.$desktop) {
        flex-direction: row;
      }
    }
  }

  &__meta {
    &-author {
      font-weight: 700;
    }
  }

  &__footer {
    &-rating {
      display: flex;
      flex-direction: row;
      gap: 1px;
      margin-bottom: 16px;
      @media (min-width: var.$desktop) {
        margin-bottom: 0;
      }

      svg {
        width: 16px;
        color: var.$secondary;

        &.empty {
          opacity: .5;
          filter: grayscale(1);
        }
      }
    }

    &-link {
      display: flex;
      align-items: center;
      color: var.$content;
      font-size: .875rem;

      img {
        margin-left: 12px;
        height: 20px;
      }
    }
  }
}

.story {
  background: transparent;
  padding: 40px 0;

  .title {
    padding: 0;
  }

  &__content {
    columns: 1;
    max-height: 256px;
    overflow: hidden;
    position: relative;
    transition: all 225ms ease-in-out;

    @media (min-width: var.$ipad) {
      max-height: initial;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      background: linear-gradient(0deg, rgba(242, 242, 242, 1) 0%, rgba(242, 242, 242, 0) 100%);

      @media (min-width: var.$ipad) {
        display: none;
      }
    }


    &.expanded {
      max-height: none;
      overflow: visible;

      &:after {
        display: none;
      }
    }

    @media (min-width: var.$ipad) {
      columns: 2;
      column-gap: 40px;
      height: initial;
    }
    @media (min-width: var.$desktop) {
      column-gap: 88px;
    }

    p {
      line-height: 160%;
      padding: 0 0 24px;
      margin: 0;
      text-align: justify;

      a {
        color: var.$content;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  .link {
    margin-top: 16px;
    @media (min-width: var.$ipad) {
      display: none;
    }
  }

}

.certificate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  background: transparent;
  gap: 40px;
  margin-top: -40px;

  hr {
    width: 100%;
    height: 1px;
    border: none;
    background: #D6D6D6;
  }

}

.producers {
  padding: 0;
  background: transparent;
  margin: 0;

  @media (max-width: var.$ipad) {
    display: none;
  }

  .title {
    padding: 0 0 8px;
    margin: 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 0;

    &-item {
      box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.15);
      padding: 0 40px;
      background: var.$white;
      list-style-type: none;
      border-radius: 16px;
      width: calc(25% - 40px);
      aspect-ratio: 4/2;
      position: relative;

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 60%;
        object-fit: contain;
        opacity: .4;
        transition: all 225ms ease-in-out;
      }

      svg {
        width: 60%;
        object-fit: contain;
        opacity: .4;
        transition: all 225ms ease-in-out;
      }

      @media (min-width: var.$desktop) {
        width: calc(16.6% - 40px);
      }

      &:hover {
        img, svg {
          opacity: 1;
        }
      }

    }
  }

}

.products{
  .product{
    &__content{
      padding-bottom: 0;
      &-name{
        .meta{
          &__meta{
            &-item{
              &:nth-child(2){
                display: inherit;
                -webkit-line-clamp: inherit;
                -webkit-box-orient: inherit;
                overflow: visible;
              }
              &:nth-child(3){
                display: flex;
                flex-direction: row;
                padding-left: 0;
                gap: 4px;
                strong {
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
                  white-space: pre-wrap; /* let the text wrap preserving spaces */
                }
              }
            }
          }
        }
      }
      &-meta{
        margin-bottom: 4px;
      }
    }
    &__form{
      &-delivery{
        display: none;
      }
    }
    .button{
      svg{
        display: none;
      }
    }
  }
}

.product {
  &__form{
    &-price{
      font-size: 1.15rem;
    }
  }
}