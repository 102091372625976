@use '../abstracts/variables' as var;
@import 'hamburgers/dist/hamburgers.css';
@import 'toastr/build/toastr.css';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 0 16px;

  @media (min-width: var.$ipad) {
    padding: 0 24px;
    gap: 24px;
  }
  @media (min-width: var.$macbook) {
    padding: 0 32px;
  }
  @media (min-width: var.$desktop) {
    margin: 0 auto;
    padding: 0;
    max-width: 1200px;
  }
  @media (min-width: 1440px) {
    max-width: 1400px;
    gap: 16px;
  }
}

#header {
  .header {
    background: var.$white;
    padding: 24px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0) 0px 7px 29px 0px;
    transition: all 500ms ease-in-out;
    max-height: 90px;
    z-index: 10;

    @media (max-width: 1024px) {
      border-radius: 0;
      padding: 16px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;

    @media (min-width: var.$desktop) {
      max-width: 1200px;
    }
    @media (min-width: 1440px) {
      max-width: 1400px;
    }

    @media (max-width: 768px) {
      justify-content: space-between;
    }

    &__logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      width: fit-content;

      &-brand {
        height: 32px;
        @media (min-width: var.$desktop) {
          height: 40px;
        }
        @media (max-width: 430px) {
          width: 100%;
          height: initial;
        }
      }

      &-badge {
        display: none;
        @media (min-width: 1440px) {
          display: block;
        }
      }
    }

    &__brands {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      img {
        height: 32px;
      }

      @media (max-width: 1280px) {
        display: none;
      }

    }

    &__nav {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      padding: 0;
      margin: 0;
      gap: 16px;

      @media (max-width: 1024px) {
        display: none;
      }

      @media (min-width: 1024px) {
        height: auto !important;
      }

      &.is-visible {
        @media (max-width: 1024px) {
          display: block;
          position: absolute;
          top: 80px;
          background: #fff;
          padding: 24px;
          left: 0;
          overflow-y: scroll;
          border-top: 1px solid darken(#fff, 10%);
          width: 40%;
          transition: all 325ms ease-in-out;
        }
        @media (max-width: 768px) {
          width: 80%;
        }
      }

      &-parent {

        @media (max-width: 1024px) {

          &:last-child {
            border-bottom: none;
          }

        }

        &.has-child {
          a {
            display: flex;
            align-content: center;
            gap: 8px;

            svg {
              width: 20px;
            }
          }
        }

        a {
          font-size: 1rem;
          font-weight: 700;
          color: var.$dark;
        }

        .nav {
          &__child {
            position: absolute;
            top: 96px;
            left: 0;
            background: var.$white;
            width: 100%;
            border-radius: 16px;
            box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

            display: flex;
            flex-direction: row;

            @media (min-width: 1024px) {
              max-height: calc(100vh - 128px);
              overflow: auto;
            }

            @media (max-width: 1024px) {
              flex-direction: column;
              bottom: 0;
              left: 0;
              height: 85vh;
              top: 0;
              overflow-y: scroll;
            }

            @media (max-width: 1024px) {
              margin: 24px 0;
              position: relative;
              height: 100%;
              padding: 0;
              width: 100%;
              overflow-y: scroll;

            }

            @media (max-width: 1024px) {
              padding: 0;
              margin: 0;
              box-shadow: none;
              border-radius: 0;
            }


            &-group {
              width: 100%;
              list-style-type: none;
              padding: 0;
              margin: 0;
              border-right: 1px solid darken(#fff, 10%);

              @media (max-width: 1024px) {
                margin-bottom: 8px;
                border-right: none;
                padding: 0 16px;
              }

              &:last-child {
                border-right: none;

                .nav {
                  &__child {
                    &-list {
                      columns: 2;
                      @media (min-width: 1280px) {
                        columns: 3;
                      }
                      @media (max-width: 1024px) {
                        columns: 2;
                      }
                      @media (max-width: 540px) {
                        columns: 1;
                      }
                    }
                  }
                }
              }

            }


            &-title {
              width: 100%;
              columns: auto;
              display: flex;
              flex-direction: column;

              span {
                font-size: 1.125rem;
                font-weight: 700;
                padding: 32px 40px;
                color: var.$dark;

                @media (max-width: 1024px) {
                  padding: 16px 0;
                  font-size: .725rem;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                  color: rgba(0, 0, 0, .5);
                }
              }
            }

            &-list {
              padding: 0 32px 32px;
              margin: 0;
              list-style-type: none;
              padding-top: 16px;
              border-top: 1px solid darken(#fff, 10%);
              columns: 2;

              @media (max-width: 1024px) {
                padding: 16px 0;
              }

              @media (max-width: 540px) {
                columns: 1 !important;
              }


              .nav {
                &__child {
                  &-item {
                    line-height: 160%;
                    @media (max-width: 1024px) {
                      line-height: 240%;
                    }

                    a {
                      color: var.$dark-light;
                      font-weight: 400;
                      padding: 12px 16px;
                      border-radius: 4px;
                      transition: all 225ms ease-in-out;

                      @media (max-width: 1024px) {
                        padding: 0;
                        font-size: 1rem;
                      }

                      &:hover {
                        background: darken(#fff, 5%);
                      }
                    }
                  }
                }
              }
            }

            &.hidden {
              display: none;
            }
          }
        }
      }

      &-link {
        @media (max-width: 1024px) {
          height: 56px;
          align-items: center;
          padding: 0 16px;
          margin-bottom: 8px;
        }
        @media (max-width: 1024px) {
          justify-content: space-between;
        }

        svg {
          transition: all 225ms ease-in-out;
        }

        &.active {
          svg {
            transform: rotate(180deg);
          }

          @media (max-width: 1024px) {
            background: darken(#fff, 5%);
            border-radius: 8px;
          }
        }
      }

    }

    &__faq {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      border: 1px solid darken(#fff, 10%);
      border-radius: 8px;
      margin-left: auto;

      @media (max-width: 728px) {
        display: none;
      }

      &-item {
        border-right: 1px solid darken(#fff, 10%);

        &:last-child {
          border-right: none;
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: .625rem;
          font-weight: 600;
          color: var.$primary;
          padding: 12px;
          transition: all 225ms ease-in-out;
          width: 64px;

          @media (max-width: 1280px) {
            width: 48px;
            height: 48px;
            padding: 0;
            justify-content: center;
            align-items: center;
            align-content: center;
          }

          &:hover {
            background: darken(#fff, 3%);
          }

          span {
            @media (max-width: 1280px) {
              display: none;
            }
          }

          svg {
            width: 16px;
            margin-bottom: 8px;
            @media (max-width: 1280px) {
              margin-bottom: 0;
              width: 20px;
            }
          }
        }
      }
    }

    &__meta {
      display: flex;
      flex-direction: row;
      gap: 8px;

      &-hamburger {
        width: 48px;
        height: 48px;
        border: 2px solid #F2F2F2;
        border-radius: 8px;
        display: flex;
        margin-left: auto;

        @media (min-width: 1024px) {
          display: none;
        }

        button {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          padding: 0;
          margin: 0;

          .hamburger {
            &-box {
              transform: scale(.6);
            }
          }
        }
      }

      &-basket {
        height: 64px;
        border: 2px solid var.$secondary;
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        border-radius: 8px;
        padding: 0 16px;
        transition: all 225ms ease-in-out;

        @media (max-width: 1440px) {
          aspect-ratio: 1/1;
          padding: 0;
          justify-content: center;
          margin-left: auto;
          height: 48px;
        }

        &:hover {
          background: lighten(#EF801A, 46%);
        }

        svg {
          @media (max-width: 1440px) {
            width: 16px;
          }

          path {
            fill: var.$secondary;
          }
        }

        .price {
          white-space: nowrap;
          font-size: 1rem;
          font-weight: 700;
          color: var.$secondary;

          display: none;

          @media (min-width: 1440px) {
            display: block;
          }

          span {
            font-size: .825rem;
            font-weight: 500;
            color: var.$dark;
          }
        }
      }

    }

    &__contact {
      position: absolute;
      top: 120px;
      right: 0;
      background: var.$white;
      padding: 40px;
      z-index: 3;
      border-radius: 16px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      min-width: 384px;
      border: 1px solid darken(#fff, 10%);
      display: none;
      max-height: 70vh;
      overflow-y: scroll;

      &.visible {
        display: block;
      }

      &-item {
        border-bottom: 1px solid darken(#fff, 10%);
        padding: 24px 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
        }

        h3 {
          padding: 0;
          margin: 0;
          padding-bottom: 16px;
          font-size: 1.5rem;
        }

        h4 {
          display: flex;
          flex-direction: row;
          align-content: center;
          align-items: center;
          gap: 8px;
          padding: 0;
          margin: 0;
          font-size: 1.075rem;

          span {
            font-size: .6rem;
            font-weight: 600;
            background: darken(#fff, 10%);
            padding: 4px;
            border-radius: 4px;
          }
        }

        p, ul {
          display: flex;
          flex-direction: column;
          padding: 0;
          font-size: 1rem;
          margin: 0;
          gap: 8px;
          list-style-type: none;

          &.address {
            font-weight: 600;
            padding-top: 8px;
            font-size: .825rem;
            flex-direction: row;
            align-items: center;
            gap: 4px;

            svg {
              width: 20px;
            }

          }

          a {
            color: var.$dark;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            gap: 4px;
            padding: 16px;
            justify-content: space-between;
            border-radius: 8px;
            border: 1px solid darken(#fff, 10%);
            transition: all 225ms ease-in-out;

            &:hover {
              background: darken(#fff, 5%);
            }

            svg {
              width: 20px;
              order: 2;
              opacity: .3;
            }
          }
        }

        .hours {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 16px 0 0;

          p {
            font-size: .825rem;

            span {
              padding-top: 0;
              font-size: 1rem;
              font-weight: 700;
            }
          }
        }

      }

    }

  }


  &.sticky {
    width: 100%;

    .header {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      width: 100%;
    }
  }

  &.hidden {
    .header {
      transform: translate(-50%, -150px);
      transition-delay: 100ms;

      .nav {
        &__child {
          display: none;
        }
      }
    }
  }
}

#searchForm {
  &.hidden {
    display: none;
  }

  .search {
    &__form {
      padding: 40px;
      border-radius: 16px;
      background: var.$white;
      display: flex;
      width: 100%;
      margin-bottom: 24px;
      gap: 16px;
      position: relative;

      .typewrite {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 16px;
        font-weight: 600;
        opacity: .5;
        z-index: 0;
        transition: all 50ms ease-in-out;

        &.hidden {
          opacity: 0;
        }

        &:after {
          content: '|';
          color: rgba(0, 0, 0, .5);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 12px;
        }
      }

      input {
        width: 100%;
        height: 56px;
        color: var.$content;
        background: none;
        z-index: 1;

        font-size: 1rem;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        border: 1px solid darken(#fff, 10%);
        border-radius: 8px;
        padding: 0 16px;
        transition: all 225ms ease-in-out;

        &::placeholder {
          color: rgba(0, 0, 0, .4);
        }

        &:active, &:focus {
          border-color: rgba(0, 0, 0, .15);
          outline: none;
          background: rgba(0, 0, 0, .04);
        }

      }

      button {
        width: max-content;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        gap: 8px;

        svg {
          width: 24px;
        }
      }
    }
  }
}

main {
  padding-top: 112px;
  margin-bottom: 32px;
}

.nice-select {
  .nice-select-dropdown {
    padding: 8px;
    width: 100%;
    margin-top: 12px !important;
    border: 1px solid darken(#fff, 5%);
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.15);

    .nice-select-search {
      font-family: "Montserrat", sans-serif;
      font-optical-sizing: auto;
    }

    .list {
      padding: 0;
      margin: 0;

      &:hover {
        li {
          &.selected {
            background: darken(#fff, 5%) !important;
            border-radius: 8px !important;
          }
        }
      }

      li {
        font-weight: 600;
        margin-bottom: 2px;
        border-radius: 8px;

        &.selected {
          background: darken(#fff, 5%) !important;
          border-radius: 8px !important;
        }

        &.optgroup {
          padding: 8px 16px;
          font-size: 0.725rem;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: rgba(0, 0, 0, 0.35);

          &::marker {
            display: none;
          }
        }
      }
    }
  }
}

section {
  background: var.$white;
  border-radius: 16px !important;
  padding: 40px 0;

  .title {
    padding: 0 24px 0;
  }

  .h2 {
    padding-top: 0;
    margin-top: 0;
  }
}

.button {
  height: 56px;
  padding: 0 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-weight: 700;
  transition: all 225ms ease-in-out;
  width: 100%;
  gap: 8px;
  @media (min-width: var.$ipad) {
    width: inherit;
  }

  svg, img {
    width: 20px;
  }

  span {
    color: var.$primary;
    font-weight: 700;
    margin-left: 8px;
    font-size: 1.25rem;
  }

  &-outline {
    border: 1px solid var.$content;
    color: var.$content;

    &:hover {
      background: rgba(0, 0, 0, .05);
    }
  }

  &-ghost {
    color: var.$content;

    &:hover {
      background: rgba(0, 0, 0, .05);
    }

    &.primary {
      color: var.$primary;
      text-align: center;
      line-height: 140%;

      &:hover {
        background: none;
      }
    }
  }

  &-primary {
    background: var.$primary;
    border: none;
    color: var.$white;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    transition: all 225ms ease-in-out;
    cursor: pointer;
    padding: 0 16px;

    img {
      @media (min-width: 768px) and (max-width: 1200px) {
        display: none;
      }
    }

    &:hover {
      background: darken(#009ee2, 5%);
    }

    &.outline {
      background: none;
      color: var.$primary;
      border: 1px solid var.$primary;
    }

  }

  &-secondary {
    background: var.$secondary;
    border: none;
    color: var.$white;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    transition: all 225ms ease-in-out;
    cursor: pointer;
    padding: 0 16px;

    img {
      @media (min-width: 768px) and (max-width: 1200px) {
        display: none;
      }
    }

    &:hover {
      background: #DB710F;
    }

  }

  &-sm {
    height: 48px;
    font-size: .825rem;
  }

  &-fixed {
    width: max-content !important;
    padding: 0 16px;
  }

  &-fill{
    width: 100%;
  }

}

.product {
  padding: 16px;
  background: var.$white;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  @media (min-width: 520px) {
    width: calc(50% - 8px);
  }

  @media (min-width: var.$ipad) {
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: 100%;
  }

  &__image {
    display: flex;
    position: relative;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(193, 193, 193, 0.20) 100%);
    aspect-ratio: 4 / 2.75;
    border-radius: 8px;

    @media (min-width: var.$ipad) {
      width: 18%;
      aspect-ratio: 1 / 1;
    }

    img {
      width: 80%;
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translateX(-50%);
      mix-blend-mode: darken;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
    box-sizing: border-box;

    @media (min-width: var.$ipad) {
      width: 80%;
      position: relative;
    }

    &-meta {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (min-width: var.$ipad) {
        justify-content: flex-start;
        gap: 16px;
        position: absolute;
        right: 0;
        top: 16px;
        background: var.$white;
        z-index: 1;
      }

      .season {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;

        &__image {
          height: 12px;
          display: flex;

          img, svg {
            width: 12px;
            height: 12px;
          }
        }

        &__title {
          font-size: .725rem;
          text-transform: uppercase;
          font-weight: 600;
        }

        &.winter .season__image path {
          fill: var.$primary;
        }

        &.winter .season__title {
          color: var.$primary;
        }

        &.summer .season__image path {
          fill: #DEAE33;
        }

        &.summer .season__title {
          color: #DEAE33;
        }

        &.allseason .season__image path {
          fill: #6a4e1a;
        }

        &.allseason .season__title {
          color: #6a4e1a;
        }
      }

      .producer {
        img, svg {
          height: 16px;
          object-fit: contain;
        }
      }
    }

    &-name {
      padding: 0;
      margin: 0;

      .title {
        padding: 0;
        margin: 0;

        a {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: var.$dark;
        }
      }

      .rating {
        display: flex;
        flex-direction: row;
        gap: 1px;
        margin-top: 4px;
        margin-bottom: 16px;

        @media (min-width: var.$desktop) {
          margin-bottom: 0;
        }

        span {
          font-size: .725rem;
          color: var.$dark-light;
          margin-left: 4px;
        }

        svg {
          width: 12px;
          color: var.$secondary;

          &.empty {
            opacity: .5;
            filter: grayscale(1);
          }
        }
      }

      .meta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        @media (min-width: var.$ipad) {
          flex-direction: row;
          align-items: center;
          margin-top: 16px;
        }

        @media (min-width: var.$macbook) {
          flex-wrap: wrap;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          flex-wrap: wrap;
        }

        &__meta {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          gap: 8px;
          list-style-type: none;

          @media (max-width: 800px) {
            flex-wrap: wrap;
          }

          @media (min-width: var.$ipad) and (max-width: var.$macbook) {
            padding-right: 16px;
          }

          &-item {
            font-size: .825rem;
            font-weight: 600;
            color: var.$dark-light;
            white-space: nowrap;

            &:first-child {
              font-weight: 700;
            }

            &:nth-child(2) {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            &:nth-child(3) {
              padding-left: 8px;


              @media (max-width: 400px) {
                padding-left: 0;
              }

              @media (min-width: 520px) and (max-width: var.$ipad) {
                padding-left: 0;
              }

              &:before {
                content: '';
                position: relative;
                left: -8px;
                @media (min-width: 520px) and (max-width: var.$ipad) {
                  display: none;
                }
                @media (max-width: 400px) {
                  display: none;
                }
              }
            }

          }
        }

        &__label {
          display: flex;
          flex-direction: row;
          gap: 24px;
          list-style-type: none;
          padding: 0;
          margin: 0;

          @media (max-width: 400px) {
            gap: 16px;
          }

          &-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;
            font-size: .755rem;
            font-weight: 600;
            color: var.$dark;
            white-space: nowrap;

            img, svg {
              width: 12px;
            }

            span {
              font-weight: 400;
              font-size: .625rem;

              &:before {
                content: '(';
              }

              &:after {
                content: ')';
              }
            }
          }
        }
      }

      .value {
        padding: 16px 0;
        margin: 0;
        list-style-type: none;
        font-size: .725rem;
        font-weight: 600;
        gap: 16px;
        display: flex;
        flex-direction: row;

        @media (min-width: var.$ipad) {
          position: absolute;
          bottom: -8px;
          flex-direction: row;
        }

        &__item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          svg, img {
            width: 16px;
          }

          &.warning {
            color: #CA3132;
          }

          &.danger {
            color: var.$secondary;
          }

          &.success {
            color: #50CA31;
          }

          span {
            color: #86BA2A;
          }

          &.deliveryTime {
            @media (max-width: var.$ipad) {
              position: absolute;
              bottom: 8px;
              left: 50%;
              transform: translateX(-50%);
              padding: 16px 0 8px;
              width: 100%;
              display: flex;
              justify-content: center;
              &:before {
                content: '';
                background: darken(#fff, 5%);
                width: 100%;
                height: 1px;
                position: absolute;
                top: 8px;
                left: 0;
              }
            }
          }

        }
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    margin-top: auto;

    @media (min-width: var.$ipad) {
      flex-direction: row;
      margin-top: auto;
      align-items: center;
    }

    &-price {
      font-size: 1.25rem;
      font-weight: 700;
      color: var.$dark;

      span {
        font-size: .825rem;
        font-weight: 400;
        color: var.$dark-light;
      }
    }

    &-basket {
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: 100%;

      @media (min-width: var.$ipad) {
        width: fit-content;
        margin-left: auto;
      }

      select {
        width: fit-content;
        display: none;
      }

      .nice-select {
        width: fit-content;
        height: 56px;
        display: flex;
        align-items: center;

        @media (min-width: var.$ipad) {
          height: 48px;
        }
      }

      button {
        gap: 8px;

        @media (min-width: 1280px) and (max-width: 1400px) {
          font-size: .875rem;
        }

        svg {
          width: 16px;

          @media (min-width: 520px) and (max-width: 687px) {
            display: none !important;
          }


          @media (min-width: 1280px) and (max-width: 1400px) {
            display: none;
          }

        }

        @media (min-width: var.$ipad) {
          height: 48px;
        }

      }

      .content {
        &__quantity {
          display: flex;
          align-items: center;
          border: 1px solid darken(#fff, 10%);
          border-radius: 8px;
          background: none;
          box-sizing: content-box;
          width: min-content;

          @media (max-width: 512px){
            width: 100%;
          }

          &-button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 1rem;
            width: 48px;
            height: 48px;
            font-weight: 600;

            &--decrement {
            }

            &--increment {
            }
          }

          &-input {
            width: 100%;
            text-align: center;
            border: none;
            font-size: 1rem;
            font-weight: 600;
            outline: none;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }

        &__basket {
          display: flex;
          flex-direction: row;
          gap: 16px;

          .content {
            &__quantity {
              input {
                width: 64px;
                @media (max-width: 480px){
                  width: 100%;
                }
              }
            }
          }

          .button {
            &-remove {
              display: flex;
              justify-content: center;
              align-content: center;
              width: 50px !important;
              height: 50px;
              padding: 0;
              margin: 0;
              border: none;
              background: #fde9e9;
              cursor: pointer;

              svg{
                path{
                  stroke: #a80606;
                }
              }

              &:hover {
                background: darken(#fde9e9, 4%);
              }
            }
          }
        }
      }

    }

    &-delivery {
      position: absolute;
      top: 17px;
      left: 0;
      padding: 0;
      margin: 0;
      @media (max-width: var.$ipad) {
        display: flex;
        position: relative;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100%;
        font-size: .725rem;
        gap: 4px;
        border-top: 1px solid darken(#fff, 5%);
        padding-top: 16px;
        margin-top: -8px;
      }
      @media (min-width: var.$ipad) {
        background: var.$white;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 150%;
          height: 1px;
          background: darken(#fff, 5%);
          z-index: 0;
        }
      }

      p {
        padding: 0;
        margin: 0;
        font-size: .755rem;
        z-index: 1;
        position: relative;
        background: var.$white;
        @media (max-width: var.$ipad) {
          font-size: .7rem;
        }
        @media (min-width: var.$ipad) {
          padding-right: 8px;
        }
      }

      span {
        color: #86BA2A;
        font-weight: 700;
      }
    }

  }
}


.products {
  position: relative;

  .tns-outer {
    padding: 0 16px !important;
  }

  &__nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    width: 100%;
    @media (max-width: 768px) {
      display: none;
    }

    &-button {
      width: 48px;
      height: 48px;
      background: var.$white;
      border: none;
      border-radius: 80px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      cursor: pointer;
      transition: all 225ms ease-in-out;

      svg {
        width: 20px;
        transition: all 225ms ease-in-out;
      }

      &:hover {
        background: var.$dark;

        svg {
          color: #fff;
        }
      }

      &.products__nav-prev {
        left: -24px !important;
        position: absolute;
      }

      &.products__nav-next {
        right: -24px !important;
        position: absolute;
      }

      &-right {
        right: 0;
      }
    }
  }

  &__carousel {
    padding: 0;
    position: relative;
    gap: 16px !important;
    display: flex;

    .product {
      border: 1px solid darken(#fff, 10%);
      @media (min-width: var.$ipad) {
        display: inline-flex !important;
        //margin: 0 16px;
      }

      &__image {
        @media (min-width: var.$ipad) {
          width: 24%;
        }
        @media (min-width: 1280px) and (max-width: 1440px) {
          width: 30%;
        }
        @media (min-width: 1440px) {
          width: 35%;
        }
      }

      &__content {
        @media (min-width: var.$ipad) {
          width: calc(100% - 24%);
        }
        @media (min-width: 1280px) and (max-width: 1440px) {
          width: 70%;
        }
        @media (min-width: 1440px) {
          width: 65%;
        }

        &-name {
          .value {
            display: none;
          }

          .meta {
            @media (min-width: 1280px) {
              flex-direction: column;
              justify-content: flex-start;
              align-content: flex-start;
              align-items: flex-start;
            }

            &__meta {
              @media (min-width: 1280px) {
                border-right: none;
              }
              &-item{
              }
            }
          }
        }

        &-meta {
          justify-content: space-between;
          width: 100%;
          //top: -8px;
          position: relative;
          margin-bottom: 8px;
        }
      }

      &__form {
        .nice-select {
          display: none;
        }
      }
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 16px;
    padding: 8px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    @media (min-width: 769px) and (max-width: 1140px) {
      flex-direction: column;
      height: 100%;
    }


    &__image {
      max-width: 192px;
      display: flex;
      width: 100%;
      aspect-ratio: 1 / 1.15;
      background: linear-gradient(0deg, rgba(193, 193, 193, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
      border-radius: 12px;
      overflow: hidden;
      position: relative;

      @media (max-width: 768px) {
        max-width: 100%;
        aspect-ratio: 1/.75;
      }

      @media (min-width: 769px) and (max-width: 1140px) {
        max-width: 100%;
        aspect-ratio: 1/.75;
      }

      &-season {
        position: absolute;
        top: 8px;
        left: 8px;

        svg {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }

        &.winter {
          svg {
            path {
              fill: var.$primary;
            }
          }
        }

        &.summer {
          svg {
            path {
              fill: var.$secondary;
            }
          }
        }
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          position: absolute;
          bottom: -48px;
          left: 50%;
          transform: translateX(-50%);
          width: 80%;
          mix-blend-mode: darken;
          object-fit: contain;
          transition: all 250ms ease-in-out;

          @media (max-width: 768px) {
            width: 75%;
            bottom: -120px;
          }

          @media (min-width: 769px) and (max-width: 1140px) {
            width: 70%;
            bottom: -128px;
          }

        }

        &:hover {
          img {
            width: 85%;
          }
        }

      }

    }

    &__content {
      width: 100%;
      padding: 8px 16px;

      @media (max-width: 768px) {
        padding: 24px;
      }

      &-meta {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style-type: none;
        font-size: .925rem;
        padding-bottom: 8px;

        @media (max-width: 768px) {
          flex-direction: row;
          flex-wrap: wrap;
        }

        li {
          color: var.$dark-light;
          margin-right: 8px;
          @media (max-width: 768px) {
            width: 33.3%;
          }

          &:first-child {
            margin-right: auto;
            @media (max-width: 768px) {
              margin-right: 0;
              width: 100%;
              padding-bottom: 8px;
            }
          }

          &.size {
            font-weight: 700;
            color: var.$content;
            @media (max-width: 768px) {
              width: max-content;
            }
          }

          &.badge {
            border: 1px solid rgba(0, 0, 0, .1);
            padding: 4px;
            border-radius: 4px;
            min-width: 32px;
            display: flex;
            justify-content: center;
            @media (max-width: 768px) {
              width: auto;
            }
          }

        }
      }

      &-title {
        font-size: 1.25rem;
        font-weight: 700;
        color: var.$dark;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        min-height: 52px;
        text-wrap: pretty;
        vertical-align: middle;
      }

      &-rating {
        display: flex;
        flex-direction: row;
        gap: 0;
        margin-top: 4px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);

        @media (min-width: var.$desktop) {
          margin-bottom: 0;
        }

        svg {
          width: 13px;
          color: var.$secondary;

          &.empty {
            opacity: .5;
            filter: grayscale(1);
          }
        }

        span {
          font-size: .725rem;
          margin-left: 4px;
          color: var.$dark-light;
        }

      }

      &-badge {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 0;
        margin: 0;
        list-style-type: none;
        @media (max-width: 768px) {
          align-items: flex-start;
        }
        @media (min-width: 380px) and (max-width: 768px) {
          align-items: center;
        }

        li {
          display: flex;
          align-items: center;
          border-right: 1px solid rgba(0, 0, 0, .1);
          padding: 0;
          padding-right: 16px;
          padding-left: 16px;
          font-weight: 600;
          font-size: .925rem;
          @media (max-width: 768px) {
            flex-direction: column;
            width: 100%;
            justify-content: center;
            padding: 0;
            height: 100%;
            display: flex;
          }
          @media (min-width: 380px) and (max-width: 768px) {
            flex-direction: row;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            border-right: none;
          }

          img {
            width: 12px;
            height: 12px;
            object-fit: contain;
            @media (max-width: 768px) {
              margin: 0 0 4px;
              padding: 0;
            }
            @media (min-width: 380px) and (max-width: 768px) {
              margin: 0;
              margin-right: 8px;
            }
          }

          span {
            margin-left: 8px;
            @media (min-width: 1024px) and (max-width: 1280px) {
              display: block;
              width: 1ch;
              overflow: hidden;
              white-space: nowrap;
            }
            @media (max-width: 768px) {
              margin-left: 0;
              margin-top: 4px;
              display: block;
              width: 1ch;
              overflow: hidden;
              white-space: nowrap;
            }
            @media (min-width: 380px) and (max-width: 768px) {
              margin-top: 0;
            }
          }

        }

      }

      &-basket {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        p {
          padding: 0;
          margin: 0;
          @media (max-width: 768px) {
            width: 100%;
            text-align: left;
          }
        }

        .button {
          margin-left: auto;
          @media (max-width: 768px) {
            margin-top: 8px;
          }

          img {
            margin-right: 8px;
          }
        }

        .price {
          font-size: 1.5rem;
          color: var.$dark;
          font-weight: 700;

          span {
            font-size: .875rem;
            font-weight: 400;
            color: var.$dark-light;
          }
        }
      }

    }

  }

}

.pagination {
  background: transparent;
  padding: 0;
  margin: 16px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &__items {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 0;
    margin: 0;

    &-single {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      font-weight: 600;
      font-size: .825rem;
      position: relative;
      overflow: hidden;

      &:nth-child(4) {
        @media (max-width: 340px) {
          display: none;
        }
      }

      &:nth-child(5) {
        @media (max-width: 380px) {
          display: none;
        }
      }

      &:nth-child(6), &:nth-child(7) {
        @media (max-width: 480px) {
          display: none;
        }
      }

      &.active {
        background: var.$white;
      }

      &.disabled {
        color: var.$dark-light;
        opacity: .5;
      }

      a {
        color: var.$dark;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        transition: all 225ms ease-in-out;

        &:hover {
          background: darken(#fff, 10%);
        }
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.keyValue {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  list-style-type: none;
  @media (max-width: var.$ipad) {
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: 100%;
    justify-content: center;
    @media (max-width: var.$ipad) {
      width: 100%;
      justify-content: space-between;
    }

    &.hidden {
      @media (max-width: var.$ipad) {
        display: none;
      }
    }

    &-title {
      font-weight: 700;
      color: var.$dark;
      order: 2;
      @media (max-width: var.$ipad) {
        order: 1;
      }
    }

    &-icon {
      order: 1;
      @media (max-width: var.$ipad) {
        order: 2;
        width: 24px;
      }
    }
  }
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: transparent;
  padding: 0;
  margin: 0;

  &__menu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 16px 0;
    margin: 0;
    justify-content: space-between;
    transition: top 300ms ease-in-out;
    transition-delay: 300ms;
    z-index: 4;

    &-item {
      display: flex;
      width: 100%;
      align-content: center;
      align-items: center;
      justify-content: center;
      border-right: 1px solid darken(#fff, 15%);

      &.hidden {
        @media (max-width: 1024px) {
          display: none;
        }
      }

      &:last-child {
        border-right: 0;
      }

      &.secondary {
        a {
          color: var.$secondary;
        }
      }

      a {
        height: 56px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        color: var.$dark-light;
        font-weight: 600;
        font-size: 1.125rem;
        transition: all 225ms ease-in-out;
        @media (max-width: 400px) {
          font-size: 1rem;
          text-align: center;
        }

        &:hover {
          color: var.$primary;
        }
      }
    }
  }

  &__content {
    padding: 24px;
    background: var.$white;
    border-radius: 16px;

    @media (min-width: var.$desktop) {
      padding: 40px;
    }

    &:first-child {
      .panel {
        &__content {
          &-title {
            margin-bottom: 16px;
          }
        }
      }
    }

    &-title {
      height: 48px;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &:has(span) {
        cursor: pointer;
      }

      .title {
        padding: 0;
        margin: 0;

        i {
          font-style: normal;
          text-decoration: underline;

          &.sum {
            text-decoration: none;
            color: var.$dark-light;
            font-weight: 600;
          }

        }
      }

      span {
        font-size: 1.5rem;
        font-weight: 600;
        color: var.$primary;
      }
    }

    &-content {
      .subtitle {
        font-size: 1.15rem;
        color: var.$secondary;
        line-height: 140%;
      }

      p {
        a {
          color: var.$primary;
          font-weight: 600;
        }
      }

      ol {
        padding: 16px 0 0 16px;
        margin: 0;
        line-height: 150%;

        li {
          padding-left: 12px;

          &::marker {
            color: var.$primary;
            font-weight: 600;
          }
        }

        ul {
          padding: 16px;
          margin: 0;
          line-height: 150%;

          li {
            margin-bottom: 16px;
            padding-left: 12px;

            &::marker {
              color: var.$primary;
              font-weight: 700;
              content: '•';
            }
          }
        }
      }

      .badge {
        margin: 16px 0;
        width: 100%;
      }

      .tabs {
        margin: 16px 0;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__content {
          border: 1px solid darken(#fff, 10%);
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          padding: 0 16px;

          .content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 0 0 16px 0;

            p {
              padding: 0;
              margin: 0;
              display: flex;
              font-size: .875rem;
            }

            a {
              color: var.$primary;
              font-weight: 600;
              font-size: .875rem;
            }

          }

          &-title {
            margin: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px 0;
            cursor: pointer;
            font-size: .875rem;

            span {
              font-size: 1.2rem;
              color: var.$primary;
            }
          }

          &.hidden {
            .content {
              display: none;
            }
          }
        }
      }

      .products {
        &__row {
          display: flex;
          overflow-x: auto;
          overflow-y: hidden;
          height: max-content;
          transition: transform 0.3s ease;
          gap: 16px;
          @media (min-width: 520px) {
            flex-direction: row;
            flex-wrap: wrap;
          }

          .product {
            border: 1px solid darken(#fff, 10%);

            &__image {
              @media (min-width: 1280px) {
                width: 12%;
              }
            }

            &__content {
              @media (min-width: 1280px) {
                width: 88%;
              }

              .product {
                &__form {
                  &-basket {
                    @media (max-width: 620px) {
                      flex-direction: column;
                    }

                    .nice-select {
                      @media (max-width: 620px) {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &__grid {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 24px;

          .product {
            display: flex;
            flex-direction: column;
            border: 1px solid darken(#fff, 10%);
            gap: 8px;
            width: 100%;
            height: auto;
            position: relative;
            padding-bottom: 48px;

            @media (min-width: 640px) {
              width: calc(50% - 16px);
            }

            @media (min-width: 1024px) {
              width: calc(33.3% - 20px);
            }

            @media (min-width: var.$desktop) {
              width: calc(25% - 20px);
            }

            &__image {
              width: 100%;
              aspect-ratio: 1 / .75;
            }

            &__content {
              width: 100%;
              padding: 0;
              margin: 0;

              &-meta {
                position: relative;
                justify-content: space-between;
              }

              &-name {
                height: 100%;
                display: flex;
                flex-direction: column;

                .meta {
                  display: flex;
                  flex-direction: column;
                }

                .title {
                  display: flex;
                  flex-direction: column;
                  min-height: 50px;
                }

                .value {
                  position: relative;
                  display: flex;
                  flex-direction: column;

                  &__item {

                    &.deliveryTime {
                      position: absolute;
                      bottom: -140px;
                      left: 50%;
                      transform: translateX(-50%);
                      padding: 16px 0 8px;
                      width: 100%;
                      display: flex;
                      justify-content: center;

                      @media (max-width: var.$ipad) {
                        bottom: -156px;
                      }

                      @media (max-width: 368px) {
                        bottom: -226px;
                      }

                      &:before {
                        content: '';
                        background: darken(#fff, 5%);
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        top: 8px;
                        left: 0;
                      }
                    }
                  }
                }

                .meta {
                  &__meta {
                    width: 100%;
                  }

                  &__label {
                    width: 100%;
                  }
                }
              }

            }

            &__form {
              display: flex;
              flex-direction: column;
              position: relative;
              justify-content: flex-start;
              align-items: flex-start;
              width: 100%;

              &-basket {
                width: 100%;
                flex-direction: column;
                @media (min-width: 369px) {
                  flex-direction: row;
                }

                .nice-select {
                  width: 100%;
                  @media (min-width: 369px) {
                    width: max-content;
                  }
                }
              }

            }

          }


          .product {
            &__content {
              &-name {
                padding-top: 8px;
              }
            }

            &__form {
              &-delivery {
                display: none !important;
              }
            }
          }

        }

      }

      .euLabel {
        display: flex;
        flex-direction: column;

        @media (min-width: var.$ipad) {
          flex-direction: row;
          gap: 40px;
        }

        .badge {
          @media (min-width: var.$ipad) {
            width: 35%;
            order: 2;
          }
        }

        .tabs {
          @media (min-width: var.$ipad) {
            width: 65%;
            order: 1;
          }

          &__content {
            @media (min-width: var.$ipad) {
              padding: 20px;
              border-radius: 16px;
            }

            &-title {
              @media (min-width: var.$ipad) {
                font-size: 1rem;
              }
            }

            .content {
              p, a {
                @media (min-width: var.$ipad) {
                  font-size: 1rem;
                }
              }
            }
          }
        }

      }

      &.vendors {
        display: flex;
        flex-direction: column;
        gap: 40px;
        @media (max-width: var.$ipad) {
          gap: 0;
        }
        @media (min-width: var.$ipad) {
          flex-direction: row;
        }
      }

    }

    &-button {
      margin: 16px 0 0;
      display: flex;
      justify-content: center;
    }


    .rating {

      &.list {
        @media (min-width: var.$ipad) {
          display: flex;
          flex-direction: row;
          gap: 80px;
        }
      }

      &__short {
        .rating {
          display: flex;
          flex-direction: column;
          padding: 16px 0;

          &__summary {
            padding-bottom: 32px;

            .summary {
              padding: 16px 0 0;
              margin: 0;
              list-style-type: none;
              line-height: 150%;
              border-top: 1px solid darken(#fff, 10%);
              margin-top: 24px;

              &__item {
                padding: 8px 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &-stars {
                  display: flex;
                  flex-direction: row;
                  gap: 1px;

                  svg {
                    width: 16px;

                    path {
                      fill: var.$secondary;
                    }

                    &.empty {
                      path {
                        fill: darken(#fff, 15%);
                      }
                    }

                    &.half{
                      path {
                        fill: url(#halfGradient);
                      }
                    }
                  }
                }

                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }

          &__related {
            border-top: 1px solid darken(#fff, 10%);
            padding-top: 32px;
          }

          &__title {
            font-size: 1.125rem !important;
            padding: 0 !important;
            margin: 0 !important;
            display: flex;
            flex-direction: column;
            line-height: 150%;

            span {
              color: var.$secondary;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              font-size: 1.25rem;
            }

            &-stars {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 1px;

              svg {
                width: 16px;

                path {
                  fill: var.$secondary;
                }

                &.empty {
                  path {
                    fill: darken(#fff, 15%);
                  }
                }

                &.half{
                  path {
                    fill: url(#halfGradient);
                  }
                }
              }
            }
          }
        }

        @media (min-width: var.$ipad) {
          margin-top: 24px;
        }
      }

      &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        list-style-type: none;
        padding: 0;
        margin: 0;

        @media (min-width: var.$ipad) {
          margin-top: 24px;
        }

        &-item {
          padding: 16px;
          border: 1px solid darken(#fff, 10%);
          border-radius: 8px;
          color: var.$dark;

          .name {
            font-weight: 600;
            font-size: .875rem;
          }

          .content {
            font-size: .875rem;
          }

          .summary {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: .875rem;
            padding-top: 16px;
            margin-top: 16px;
            border-top: 1px solid darken(#fff, 10%);

            &__evaluation {
              display: flex;
              flex-direction: column;

              span {
                font-weight: 600;
                margin-top: 4px;
              }
            }

            &__stars {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 1px;

              svg {
                width: 16px;

                path {
                  fill: var.$secondary;
                }

                &.empty {
                  path {
                    fill: darken(#fff, 15%);
                  }
                }
              }
            }
          }
        }
      }

    }

    .vendor {
      width: 100%;

      &:nth-child(1) {
        @media (min-width: var.$ipad) {
          max-width: 20%;
        }
      }

      &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        pointer-events: none;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: -16px;
          left: 0;
          width: 48px;
          height: 1px;
          background: darken(#fff, 10%);
          @media (max-width: var.$ipad) {
            display: none;
          }
        }

        @media (max-width: var.$ipad) {
          height: 48px;
          align-items: center;
          padding: 0;
          margin: 0;
          cursor: pointer;
          pointer-events: all;
        }

        span {
          color: var.$primary;
          font-size: 1.2rem;
          @media (min-width: var.$ipad) {
            display: none;
          }
        }
      }

      &__list {
        columns: 1;
        padding: 12px 0;
        margin: 0;
        list-style-type: none;

        &.hidden {
          @media (max-width: 767px) {
            display: none;
          }
        }

        @media (max-width: 360px) {
          columns: 1 !important;
        }

        @media (min-width: 360px) and (max-width: 640px) {
          columns: 2 !important;
        }

        @media (min-width: 640px) and (max-width: 768px) {
          columns: 3 !important;
        }

        &.medium {
          columns: 2;
        }

        &.large {
          columns: 3;
          @media (min-width: var.$ipad) and (max-width: var.$macbook) {
            columns: 2;
          }
        }

        &-item {
          margin-bottom: 20px;

          a {
            font-size: 1rem;
            color: var.$dark-light;
            transition: all 225ms ease-in-out;

            &:hover {
              color: var.$primary;
            }
          }
        }
      }
    }

  }
}


footer {
  &.wrapper {
    @media (min-width: var.$uhd) {
      max-width: 1560px !important;
    }
  }
}

.panel{
  .link {
    color: var.$primary;
    font-weight: 600;
    text-decoration: underline;
    transition: all 225ms ease-in-out;
    font-size: 1rem;

    &:hover {
      color: darken(var.$primary, 10%)
    }

  }
}

hr {
  background-color: darken(#fff, 10%);
  height: 1px;
  border: 0;
}

.blog {
  &__items {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 0 40px 40px;

    @media (max-width: var.$ipad) {
      padding: 0 24px 24px;
    }

    @media (min-width: var.$desktop) {
      gap: 32px;
    }

    &-title {
      padding: 0;
      margin: 0;
      margin-bottom: 24px;

      a {
        color: var.$dark-light;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        font-weight: 700;
        font-size: 1rem;
        transition: all 225ms ease-in-out;

        &:hover {
          color: var.$primary;
        }
      }

      svg {
        width: 16px;
        margin-left: 4px;
      }
    }

    &-item {
      width: 100%;
      min-width: 280px;

      .post {
        display: flex;
        position: relative;
        width: 100%;
        aspect-ratio: 9/11;
        border-radius: 16px;
        overflow: hidden;
        background: var.$dark;

        @media (min-width: var.$desktop) {
          aspect-ratio: 4/3;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
          opacity: .5;
          transition: all 225ms ease-in-out;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 0;
          transition: all 225ms ease-in-out;

          @media (max-width: var.$desktop) {
            opacity: .5;
          }
        }

        &__content {
          padding: 1.5rem;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 12px;

          &-title {
            font-size: 1rem;
            padding: 0;
            margin: 0;
            color: var.$white;
            line-height: 150%;
          }

          &-excerpt {
            font-size: .875rem;
            padding: 0;
            margin: 0;
            display: none;
            color: var.$white;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            transition: max-height 225ms ease-in-out;

            @media (min-width: var.$desktop) {
              max-height: 0;
            }

          }

          &-date {
            font-size: .875rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var.$white;
            opacity: .8;

            svg {
              width: 16px;
              margin-right: 8px;

            }
          }
        }


        &:hover {
          img {
            width: 110%;
            height: 110%;
            opacity: .5;
          }

          &:after {
            opacity: 1;
          }

          .post {
            &__content {
              &-excerpt {
                max-height: max-content;
              }
            }
          }
        }

      }
    }
  }
}

svg {

  &.full {
    path {
      fill: var.$secondary !important;
    }
  }

  &.empty {
    opacity: 1 !important;
    path {
      fill: #cccccc !important;
    }
  }

  &.half{
    path {
      fill: url(#halfGradient) !important;
    }
  }
}

.nice-select-dropdown .list::-webkit-scrollbar {
  background: transparent;
}

.nice-select-dropdown .list::-webkit-scrollbar:vertical {
  width:1em;
}

.nice-select-dropdown .list::-webkit-scrollbar:horizontal {
  height: 0;
}

.nice-select-dropdown .list::-webkit-scrollbar-thumb {
  background-color:rgba(0,0,0,.3);
  border-radius: 99999px;
  border:.3125em solid transparent;
  background-clip: content-box;
}

.toast-top-right {
  top: 10px !important;;
  right: 10px !important;;
}

.toast-success {
  background-color: #2ca02c !important;;
  color: #ffffff !important;;
}

.toast-error {
  background-color: #d9534f !important;;
  color: #ffffff !important;;
}

.toast-info {
  background-color: #0275d8 !important;;
  color: #ffffff !important;;
}

.toast-warning {
  background-color: #f0ad4e !important;;
  color: #ffffff !important;;
}

.toast-close-button {
  color: #ffffff !important;;
  opacity: 0.9 !important;;
}

.toast-close-button:hover {
  color: #f8f9fa !important;;
  opacity: 1 !important;;
}

.toast {
  border-radius: 6px !important;;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
}

#toast-container > .toast-error {background-image: none !important;}

#toast-container > div{
  padding: 12px !important;
}