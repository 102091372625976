@use '../abstracts/variables' as var;

$font-size: 16px !default;

body {
  font-size: $font-size;
}

// Headings

$_head-font-weight: 700;

h1, h2, h3, h4, h5, h6 {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: var.$dark;
  font-weight: 600;
}

// Headlines

h1, .h1 {
  font-weight: $_head-font-weight;
  font-size: 32px;
  line-height: 40px;
}

h2, .h2 {
  font-weight: $_head-font-weight;
  font-size: 24px;
  line-height: 30px;
}

h3, .h3 {
  font-weight: $_head-font-weight;
  font-size: 20px;
  line-height: 25px;
}

h4, .h4 {
  font-weight: $_head-font-weight;
  font-size: 16px;
  line-height: 20px;
}

h5, .h5 {
  font-weight: $_head-font-weight;
  font-size: 14px;
  line-height: 18px;
}

h6, .h6 {
  font-weight: $_head-font-weight;
  font-size: 13.7px;
  line-height: 18px;
  color: #6a737d;
}