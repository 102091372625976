@use '../abstracts/variables' as var;

.mt {
  margin-top: var.$gap;
}

.mt-xs {
  margin-top: var.$gap-xs;
}

.mt-sm {
  margin-top: var.$gap-sm;
}

.mt-md {
  margin-top: var.$gap-md;
}

.mt-lg {
  margin-top: var.$gap-lg;
}

.mt-xl {
  margin-top: var.$gap-xl;
}

.mb {
  margin-top: var.$gap;
}

.mb-xs {
  margin-top: var.$gap-xs;
}

.mb-sm {
  margin-bottom: var.$gap-sm;
}

.mb-md {
  margin-bottom: var.$gap-md;
}

.mb-lg {
  margin-bottom: var.$gap-lg;
}

.mb-xl {
  margin-top: var.$gap-xl;
}

.ml {
  margin-top: var.$gap;
}

.ml-xs {
  margin-top: var.$gap-xs;
}

.ml-sm {
  margin-left: var.$gap-sm;
}

.ml-md {
  margin-left: var.$gap-md;
}

.ml-lg {
  margin-left: var.$gap-lg;
}

.ml-xl {
  margin-top: var.$gap-xl;
}

.mr {
  margin-top: var.$gap;
}

.mr-xs {
  margin-top: var.$gap-xs;
}

.mr-sm {
  margin-right: var.$gap-sm;
}

.mr-md {
  margin-right: var.$gap-md;
}

.mr-lg {
  margin-right: var.$gap-lg;
}

.mr-xl {
  margin-top: var.$gap-xl;
}
