$page-max-width: 1280px !default;
$content-max-width: 960px !default;

$gap-xxl: 32px !default;
$gap-xl: 24px !default;
$gap-lg: 16px !default;
$gap: 8px !default;
$gap-md: 4px !default;
$gap-sm: 2px !default;
$gap-xs: 1px !default;

$primary: #009EE2;
$secondary: #EF801A;
$dark: #373737;
$dark-light: #7C7C81;
$white: #FFF;
$content: #4A4A4D;

$ipad: 768px;
$macbook: 1024px;
$desktop: 1280px;
$uhd: 1680px;