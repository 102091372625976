*:not(img),
*::before,
*::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
  overflow-x: hidden;
}

html,
body,
pre {
  padding: 0;
  margin: 0;
}

a {
  background-color: transparent;
  text-decoration: none;

  &:active,
  &:hover {
    outline: 0;
  }
}

button,
select {
  text-transform: none;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

pre {
  overflow: auto;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    padding: 0;
  }
}

[hidden],
#template,
template {
  display: none;
}